const theme = {
    colorBlack: "#000",
    colorWhite: "#fff",
    colorOffWhite: "#ebedee",
    colorLightGrey: "#919191",
    colorDarkGrey: "#555",
    colorCharcoal: "#333",
    colorDarkCharcoal: "#111",
    colorRed: "tomato",
    colorTextPrimary: "#aaa",
    colorBorder: "#aaa",
    colorSidebar: "#232323",
    fontHeading: "'Montserrat', sans-serif",
    fontBody: "'Poppins', sans-serif",
    dropShadow: "7px 7px 5px rgba(0, 0, 0, 0.2)",
    primaryColor: "#2a3d54",
    secondaryColor: "#edcf00",
    tertiaryColor: "#416d81",
    subTitleColor: "#444"
};
export default theme;
