import styled from "styled-components";
import theme from "styles/theme";
import { move, upDown } from "styles/animations";
import { device } from "styles/utils";
import { HashLink } from "react-router-hash-link";
export const Container = styled.div `
  height: 750px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23fdfdfd' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23fbfbfb' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23f8f9f8' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23f6f7f6' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23f4f5f4' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  @media screen and ${`(min-height: 740px)`} {
    height: 100vh;
  }

  @media screen and ${device.laptop} {
    flex-direction: row;
  }
`;
export const LeftContainer = styled.div `
  display: grid;
  grid-template-rows: 35% 65%;
  flex: 0.4;

  @media screen and ${device.mobileL} {
    flex: 0.75;
  }

  @media screen and ${device.laptop} {
    flex: 1;
  }
`;
export const RightContainer = styled.div `
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    160deg,
    ${theme.primaryColor} 0%,
    ${theme.tertiaryColor} 100%
  );

  @media screen and ${device.mobileL} {
    flex: 0.75;
  }

  @media screen and ${device.laptop} {
    flex: 1.5;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  }

  @media screen and ${device.laptopL} {
    flex: 1.25;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  }
`;
export const ContentContainer = styled.div `
  display: grid;
  grid-template-rows: auto 20px;
  justify-content: center;
  margin: 0 2em;
  grid-gap: 1em;
  @media screen and ${device.mobileL} {
    grid-template-rows: auto 80px;
  }
  @media screen and ${device.laptop} {
    max-width: unset;
    margin: 15em 3em 3em 13em;
    grid-template-rows: auto auto 150px;
    grid-gap: 2em;}
  }
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const TitleContainer = styled.div `
  display: grid;
  grid-template-rows: 1fr 5px;
  grid-gap: 1em;
  flex: 1;
`;
export const Title = styled.div `
  font-family: ${theme.fontHeading};
  font-size: 1.5em;
  font-weight: bolder;

  @media screen and ${device.mobileL} {
    font-size: 2em;
  }

  @media screen and ${device.laptop} {
    font-size: 3em;
  }
`;
export const Title1 = styled.span `
  color: ${theme.colorWhite};
`;
export const Title2 = styled.span `
  color: ${theme.secondaryColor};
  margin-left: 0.2em;
`;
export const TitleUnderline = styled.div `
  background-color: ${theme.secondaryColor};
`;
export const TitleUnderlineOverlay = styled.div `
  background-color: ${theme.colorWhite};
  opacity: 0.5;
  height: 100%;
  animation: ${move} 2.5s infinite;
  box-shadow: 0 1px 10px 0 #0ff;
  width: 5px;
`;
export const Subtitle = styled.div `
  flex: 1;
  font-family: ${theme.fontBody};
  margin-top: 1.3em;
  font-size: 1em;
  color: ${theme.colorWhite};
  line-height: 1.5em;
  font-weight: lighter;
  max-width: 500px;

  @media screen and ${device.laptopL} {
    font-size: 1.2em;
  }
`;
export const ImageContainer = styled.div `
  grid-row: 2/2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  justify-self: center;

  @media screen and ${device.tablet} {
    width: 90%;
  }

  @media screen and ${device.laptop} {
    width: 90%;
  }
`;
export const LogoContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
export const Logo = styled.img `
  @media screen and ${device.laptopL} {
    height: 140px;
  }
  height: 80px;
`;
export const ButtonContainer = styled.div `
  display: none;

  & > a {
    display: inline-grid;
    text-decoration: none;
  }

  @media screen and ${device.laptop} {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.2em;
  }
`;
export const NavButton = styled.button `
  height: 3em;
  border: 0.1em solid ${theme.secondaryColor};
  border-radius: 1.5em;
  background-color: transparent;
  font-family: ${theme.fontBody};
  font-size: 0.9em;
  cursor: pointer;
  color: ${theme.colorWhite};

  @media (hover: hover) {
    :hover {
      background-color: ${theme.secondaryColor};
      color: black;
      transition: background-color 0.5s ease;
    }
  }
`;
export const IconContainer = styled.div `
  margin-top: 1.5em;
  font-size: 1em;
  justify-self: center;
  animation: ${upDown} 2.5s infinite;
  align-self: center;
  @media screen and ${device.laptop} {
    font-size: 2em;
  }
`;
export const HashNavLink = styled(HashLink) `
  height: 3em;
  border: 0.1em solid ${theme.secondaryColor};
  border-radius: 1.5em;
  background-color: transparent;
  font-family: ${theme.fontBody};
  font-size: 0.9em;
  cursor: pointer;
  color: ${theme.colorWhite};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (hover: hover) {
    :hover {
      background-color: ${theme.secondaryColor};
      color: black;
      transition: background-color 0.5s ease;
    }
  }
`;
