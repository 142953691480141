import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import ednar from "assets/images/logos/ednar.svg";
import lasarus from "assets/images/logos/lasarus.svg";
import plannerToolbox from "assets/images/logos/planner.svg";
import { useTranslation } from "react-i18next";
import { ednarPath, lasarusPath, plannerToolboxPath, solutionsPath } from "../../util/path";
import Heading from "components/Heading";
import SoftwareItem from "components/SoftwareItem";
import * as commonStyles from "styles/common-styles";
const { Container, List, FindButtonContainer } = styles;
const { TitleContainer, Button: FindButton } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("software-solutions");
    return (React.createElement(Container, null,
        React.createElement(TitleContainer, null,
            React.createElement(Heading, { title: t("products") })),
        React.createElement(List, null,
            React.createElement(SoftwareItem, { logoSrc: ednar, title: "EDNAR", subTitle: t("ednarSubTitle"), path: ednarPath, themeColor: "56cded" }),
            React.createElement(SoftwareItem, { logoSrc: plannerToolbox, title: "Planner Toolbox", subTitle: t("plannerToolboxSubTitle"), path: plannerToolboxPath, themeColor: "47dcd8" }),
            React.createElement(SoftwareItem, { logoSrc: lasarus, title: "LASARUS", subTitle: t("lasarusSubTitle"), path: lasarusPath, themeColor: "b676d3" })),
        React.createElement(FindButtonContainer, null,
            React.createElement(Link, { to: solutionsPath },
                React.createElement(FindButton, null, t("buttonText"))))));
});
