import React from "react";
import * as styles from "./styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Container, NavLink, HashNavLink } = styles;
export default () => {
    const { t } = useTranslation("nav");
    return (React.createElement(Container, null,
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/solutions" }, t("solutions"))),
        React.createElement(HashNavLink, { to: "/#products" }, t("products")),
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/about-us" }, t("aboutUs"))),
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/contact" }, t("contact")))));
};
