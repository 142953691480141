import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
import { HashLink } from "react-router-hash-link";
export const Container = styled.nav `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    text-decoration: none;
    color: ${theme.colorWhite};
  }
`;
export const NavLink = styled.div `
  border-right: 2px solid ${theme.colorWhite};
  padding: 0.2em 2em;
  margin-bottom: 1em;
  font-size: 0.9em;
  &:last-of-type {
    border-right: none;
  }

  @media screen and ${device.mobileL} {
    font-size: 1em;
  }
`;
export const HashNavLink = styled(HashLink) `
  border-right: 2px solid ${theme.colorWhite};
  padding: 0.2em 2em;
  margin-bottom: 1em;
  font-size: 0.9em;

  @media screen and ${device.mobileL} {
    font-size: 1em;
  }
`;
