import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import jumbotronImage from "assets/images/jumbo.png";
import logo from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { aboutUsPath, contactPath, solutionsPath } from "../../util/path";
import { FaChevronDown } from "react-icons/fa";
import { IconContext } from "react-icons";
import Image from "components/Image";
const { Container, ImageContainer, LeftContainer, RightContainer, Title, Title1, Title2, Subtitle, TextContainer, TitleContainer, TitleUnderline, TitleUnderlineOverlay, ContentContainer, Logo, LogoContainer, ButtonContainer, NavButton, IconContainer, HashNavLink } = styles;
export default memo(() => {
    const { t } = useTranslation(["home", "nav"]);
    return (React.createElement(Container, null,
        React.createElement(LeftContainer, null,
            React.createElement(LogoContainer, null,
                React.createElement(Logo, { src: logo })),
            React.createElement(ImageContainer, null,
                React.createElement(Image, { alt: "zepben", src: jumbotronImage }))),
        React.createElement(RightContainer, null,
            React.createElement(ContentContainer, null,
                React.createElement(TextContainer, null,
                    React.createElement(TitleContainer, null,
                        React.createElement(Title, null,
                            React.createElement(Title1, null, t("jumbotron.title").split(" ")[0]),
                            React.createElement(Title2, null, t("jumbotron.title").split(" ")[1])),
                        React.createElement(TitleUnderline, null,
                            React.createElement(TitleUnderlineOverlay, null))),
                    React.createElement(Subtitle, null, t("jumbotron.subTitle"))),
                React.createElement(ButtonContainer, null,
                    React.createElement(Link, { to: solutionsPath },
                        React.createElement(NavButton, null, t("nav:solutions"))),
                    React.createElement(HashNavLink, { to: "/#products" }, t("nav:products")),
                    React.createElement(Link, { to: aboutUsPath },
                        React.createElement(NavButton, null, t("nav:aboutUs"))),
                    React.createElement(Link, { to: contactPath },
                        React.createElement(NavButton, null, t("nav:contact")))),
                React.createElement(IconContainer, null,
                    React.createElement(IconContext.Provider, { value: { color: "white" } },
                        React.createElement(FaChevronDown, null)))))));
});
