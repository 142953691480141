import React, { memo } from "react";
import * as commonStyles from "styles/common-styles";
import * as styles from "./styles";
import ProductDetails from "components/SolutionDetails";
import plannerToolbox from "assets/images/logos/planner.svg";
import { useTranslation } from "react-i18next";
import SoftwareSolutions from "components/SoftwareSolutions";
import Image from "components/Image";
import plannerToolbox1 from "assets/images/planner-toolbox/image1.png";
import plannerToolbox2 from "assets/images/planner-toolbox/image2.png";
import plannerToolbox3 from "assets/images/planner-toolbox/image3.png";
import plannerToolbox4 from "assets/images/planner-toolbox/image4.png";
import plannerToolbox5 from "assets/images/planner-toolbox/image5.png";
import plannerToolbox6 from "assets/images/planner-toolbox/image6.png";
import pandapower_logo from "assets/images/planner-toolbox/pandapower_logo.png";
import siemens_logo from "assets/images/planner-toolbox/siemens_logo.png";
const { Description, DescriptionList, DescriptionText, DescriptionTitle, Grid, ProductContainer } = commonStyles;
const { LogoList } = styles;
export default memo(() => {
    const { t } = useTranslation("planner-toolbox");
    return (React.createElement(ProductContainer, null,
        React.createElement(ProductDetails, { title: t("title"), summary: React.createElement(React.Fragment, null,
                React.createElement(DescriptionText, null, t("summary1")),
                React.createElement(DescriptionText, null, t("summary2"))), logoSrc: plannerToolbox, description: React.createElement(React.Fragment, null,
                React.createElement(Description, null,
                    React.createElement(DescriptionTitle, null, t("description1Title")),
                    React.createElement(DescriptionList, null,
                        React.createElement("li", null, t("description1List1")),
                        React.createElement("li", null, t("description1List2")),
                        React.createElement("li", null, t("description1List3")),
                        React.createElement("li", null, t("description1List4")),
                        React.createElement("li", null, t("description1List5")),
                        React.createElement("li", null, t("description1List6")),
                        React.createElement("li", null, t("description1List7")),
                        React.createElement("li", null, t("description1List8")),
                        React.createElement("li", null, t("description1List9")))),
                React.createElement(Description, null,
                    React.createElement(DescriptionTitle, null, t("description2Title")),
                    React.createElement(Grid, null,
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description2Text1")),
                            React.createElement(DescriptionText, null, t("description2Text2"))),
                        React.createElement(Image, { src: plannerToolbox1, alt: t("description2Image1Alt") })),
                    React.createElement(Grid, null,
                        React.createElement(Image, { src: plannerToolbox2, alt: t("description2Image2Alt") }),
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description2Text3"))))),
                React.createElement(Description, null,
                    React.createElement(DescriptionTitle, null, t("description3Title")),
                    React.createElement(Grid, null,
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description3Text1"))),
                        React.createElement(Image, { src: plannerToolbox3, alt: t("description3Image1Alt") }))),
                React.createElement(Description, null,
                    React.createElement(DescriptionTitle, null, t("description4Title")),
                    React.createElement(Grid, null,
                        React.createElement(Image, { src: plannerToolbox4, alt: t("description4Image1Alt") }),
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description4Text1")),
                            React.createElement(DescriptionText, null, t("description4Text2")))),
                    React.createElement(Grid, null,
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description4Text3"))),
                        React.createElement(Image, { src: plannerToolbox5, alt: t("description4Image2Alt") }))),
                React.createElement(Description, null,
                    React.createElement(DescriptionTitle, null, t("description5Title")),
                    React.createElement(Grid, null,
                        React.createElement("div", null,
                            React.createElement(DescriptionText, null, t("description5Text1")),
                            React.createElement(DescriptionText, null, t("description5Text2")),
                            React.createElement(DescriptionText, null, t("description5Text3")),
                            React.createElement(DescriptionText, null, t("description5Text4"))),
                        React.createElement(Image, { src: plannerToolbox6, alt: t("description5Image1Alt") })),
                    React.createElement(LogoList, null,
                        React.createElement("a", { href: "https://pandapower.readthedocs.io/en/v2.2.2/index.html", target: "_blank" },
                            React.createElement(Image, { src: pandapower_logo, alt: t("pandapowerLogoAlt") })),
                        React.createElement("a", { href: "https://new.siemens.com/global/en/products/energy/services/transmission-distribution-smart-grid/consulting-and-planning/pss-software/pss-sincal.html", target: "_blank" },
                            React.createElement(Image, { src: siemens_logo, alt: t("siemensLogoAlt") }))))) }),
        React.createElement(SoftwareSolutions, null)));
});
