import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const Container = styled.div `
  background-color: ${theme.colorWhite};
  padding: 2em;
  margin: 2em 0;
  width: 80%;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.2em;

  @media screen and ${device.mobileL} {
    margin: 2em;
    padding: 3em;
    width: 77%;
  }

  @media screen and ${device.laptopL} {
    margin: 7em 10em;
  }
`;
export const Copy = styled.div `
  padding: 0 0 2em 0;
  line-height: 1.6em;
`;
export const Title = styled.div `
  font-weight: bolder;
  font-size: 1.5em;
`;
export const Subtitle = styled.div `
  font-weight: bolder;
  font-size: 1.2em;
`;
export const ImageContainer = styled.div `
  height: 200px;
  padding: 2em;

  img {
    max-height: 100%;
  }

  img {
    max-height: 100%;
  }

  img {
    max-height: 100%;
  }

  @media screen and ${device.mobileL} {
    height: 250px;
  }

  @media screen and ${device.tablet} {
    height: 320px;
  }
`;
export const InformationContainer = styled.div `
  margin-top: 1.2em;
  display: flex;
  flex-direction: column;

  @media screen and ${device.laptopM} {
    flex-direction: row;
  }
`;
