import React, { memo } from "react";
import * as styles from "./styles";
import * as commonStyles from "styles/common-styles";
import platform from "assets/images/what-we-do/platform.svg";
import tools from "assets/images/what-we-do/tools.svg";
import today from "assets/images/what-we-do/today.svg";
import tomorrow from "assets/images/what-we-do/tomorrow.svg";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import Image from "components/Image";
const { Container, ItemsContainer, ItemHeading, ItemDescription, ItemMain, ItemGroup, ItemContainer, ItemImageContainer, Text } = styles;
const { TitleContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("home");
    return (React.createElement(Container, null,
        React.createElement(TitleContainer, null,
            React.createElement(Heading, { title: t("whatWeDo.title") }),
            React.createElement(Text, null, t("whatWeDo.subTitle"))),
        React.createElement(ItemsContainer, null,
            React.createElement(ItemGroup, null,
                React.createElement(ItemContainer, null,
                    React.createElement(ItemImageContainer, null,
                        React.createElement(Image, { alt: "platform", src: platform })),
                    React.createElement(ItemMain, null,
                        React.createElement(ItemHeading, null, t("whatWeDo.platformTitle"))),
                    React.createElement(ItemDescription, null, t("whatWeDo.platformText"))),
                React.createElement(ItemContainer, null,
                    React.createElement(ItemImageContainer, null,
                        React.createElement(Image, { alt: "apps", src: tools })),
                    React.createElement(ItemMain, null,
                        React.createElement(ItemHeading, null, t("whatWeDo.platformAppsTitle"))),
                    React.createElement(ItemDescription, null, t("whatWeDo.platformAppsText"))))),
        React.createElement(ItemsContainer, null,
            React.createElement(ItemGroup, null,
                React.createElement(ItemContainer, null,
                    React.createElement(ItemImageContainer, null,
                        React.createElement(Image, { alt: "today", src: today })),
                    React.createElement(ItemMain, null,
                        React.createElement(ItemHeading, null, t("whatWeDo.todayTitle"))),
                    React.createElement(ItemDescription, null, t("whatWeDo.todayText"))),
                React.createElement(ItemContainer, null,
                    React.createElement(ItemImageContainer, null,
                        React.createElement(Image, { alt: "tomorrow", src: tomorrow })),
                    React.createElement(ItemMain, null,
                        React.createElement(ItemHeading, null, t("whatWeDo.tomorrowTitle"))),
                    React.createElement(ItemDescription, null, t("whatWeDo.tomorrowText")))))));
});
