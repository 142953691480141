import styled from "styled-components";
import theme from "styles/theme";
export const HeadingContainer = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  font-weight: bolder;
  color: ${theme.primaryColor};
`;
export const HeadingText = styled.span ``;
