import React, { memo } from "react";
import * as styles from "./styles";
import { IoIosCall, IoMdMail, IoMdMap } from "react-icons/io";
import contact from "assets/images/contact-us/contact.png";
import { useTranslation } from "react-i18next";
import Image from "components/Image";
const { Container, Title, ContactRow, IconContainer, ImageContainer, InformationContainer, Information } = styles;
export default memo(() => {
    const { t } = useTranslation("contact");
    return (React.createElement(Container, null,
        React.createElement(Title, null, t("title")),
        React.createElement("div", null, t("subTitle")),
        React.createElement(InformationContainer, null,
            React.createElement(ImageContainer, null,
                React.createElement(Image, { alt: "contact", src: contact })),
            React.createElement(Information, null,
                React.createElement(ContactRow, null,
                    React.createElement(IconContainer, null,
                        React.createElement(IoMdMail, null)),
                    React.createElement("a", { href: t("email") },
                        " ",
                        t("email"),
                        " ")),
                React.createElement(ContactRow, null,
                    React.createElement(IconContainer, null,
                        React.createElement(IoMdMap, null)),
                    t("address")),
                React.createElement(ContactRow, null,
                    React.createElement(IconContainer, null,
                        React.createElement(IoIosCall, null)),
                    t("phoneNumber"))))));
});
