import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import * as commonStyles from "styles/common-styles";
import { useTranslation } from "react-i18next";
import { contactPath } from "../../util/path";
const { Container, ContactRow, ContactCol, ContactText } = styles;
const { Button: ContactUsButton } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("home");
    return (React.createElement(Container, null,
        React.createElement(ContactRow, null,
            React.createElement(ContactCol, null,
                React.createElement(ContactText, null, t("contactUs.subTitle")),
                React.createElement(Link, { to: contactPath },
                    React.createElement(ContactUsButton, null, t("contactUs.buttonText")))))));
});
