import styled from "styled-components";
import { device } from "styles/utils";
export const Container = styled.div `
  width: 100%;
  height: 100px;
  background-color: #3b5573;
  color: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const Content = styled.div `
  text-align: center;
`;
export const Legals = styled.div `
  text-align: center;
  font-weight: 300;
  font-size: 14px;
`;
export const Logo = styled.img `
  display: none;
  @media screen and ${device.tablet} {
    display: block;
    margin: 0.5em 1.5em;
    height: 50px;
    position: absolute;
    left: 1.5em;
    top: 1.1em;
  }
`;
