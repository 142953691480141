import styled from "styled-components";
import { device } from "styles/utils";
export const Container = styled.div `
  width: 100%;
`;
export const ItemContainer = styled.div `
  margin: 1em 0;
  @media screen and ${device.tablet} {
    margin: 2em;
  }
  @media screen and ${device.laptopL} {
    margin: 2em 10em;
  }
`;
