import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const DescriptionsContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2em;
  grid-column-gap: 1.5em;
  justify-items: center;
  @media screen and ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 5em;
  }
`;
export const DescriptionImage = styled.img `
  height: 13em;

  @media screen and ${device.tablet} {
    height: 20em;
  }

  align-self: center;
`;
export const Description = styled.section `
  display: grid;
  grid-template-rows: 40px auto;
  align-self: center;
  padding: 1em 0;
`;
export const DescriptionTitle = styled.div `
  font-weight: 500;
  font-size: 1.5rem;
`;
export const DescriptionText = styled.div `
  color: ${theme.colorDarkGrey};
  padding: 0.5em 0;
`;
export const DescriptionCta = styled.div `
  font-size: 1.1rem;
  font-weight: 700;
`;
export const DescriptionList = styled.ul `
  list-style-type: disc;
  padding-left: 1.2em;

  & li {
    padding: 0.5em 0;
  }

  & li:first-of-type {
    padding-top: 0;
  }

  & li:last-of-type {
    padding-bottom: 0;
  }
`;
export const Grid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  padding: 1em 0;
  align-items: center;

  img {
    justify-self: center;
    border-radius: 1em;
    max-width: 500px;
  }
`;
export const ImageSet = styled.div `
  display: flex;

  img {
    max-width: 50%;
    padding: 0 0.5em;
  }
`;
export const Subtitle = styled.div `
  color: ${theme.colorDarkGrey};

  @media screen and ${device.mobileL} {
    font-size: 0.9em;
  }
`;
export const TitleContainer = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1em;
`;
export const Button = styled.button `
  padding: 1em 2em;
  background-color: ${theme.primaryColor};
  font-family: ${theme.fontBody};
  font-size: 0.9rem;
  cursor: pointer;
  color: ${theme.colorWhite};
  font-weight: lighter;
  border: none;

  @media (hover: hover) {
    :hover {
      background-color: ${theme.tertiaryColor};
      transition: background-color 0.5s ease;
    }
  }
`;
export const ProductContainer = styled.div `
  width: 100%;
  @media screen and ${device.laptopM} {
    width: 77%;
  }
`;
