import React, { memo } from "react";
import * as styles from "./styles";
import * as commonStyles from "styles/common-styles";
import ProductDetails from "components/SolutionDetails";
import lasarus from "assets/images/logos/lasarus.svg";
import { useTranslation } from "react-i18next";
import lasarus1 from "assets/images/lasarus/lasarus1.png";
import SoftwareSolutions from "components/SoftwareSolutions";
const { Image } = styles;
const { ProductContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("lasarus");
    return (React.createElement(ProductContainer, null,
        React.createElement(ProductDetails, { title: t("title"), summary: t("summary"), logoSrc: lasarus, description: React.createElement(React.Fragment, null,
                t("description")
                    .split("\n")
                    .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))),
                React.createElement(Image, { src: lasarus1 })) }),
        React.createElement(SoftwareSolutions, null)));
});
