import styled from "styled-components";
export const Image = styled.img `
  margin: 2em 0;
  max-height: 20em;
  justify-self: center;
`;
export const LogoList = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
