import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const Container = styled.div `
  padding: 3em;
  background-color: ${theme.colorWhite};
  display: none;
  @media screen and ${device.laptop} {
    display: block;
  }
`;
export const TestimonialContainer = styled.div `
  margin-top: 2em;
  display: grid;
  grid-template-areas:
    "testimonial"
    "logo-testimonial"
    "logo";
  grid-column-gap: 2em;
  grid-row-gap: 4em;

  @media screen and ${device.tablet} {
    grid-row-gap: unset;
    grid-template-areas:
      "testimonial logo-testimonial logo-testimonial logo-testimonial"
      "testimonial logo logo logo";
  }
`;
export const Testimonial = styled.div `
  background-color: ${theme.primaryColor};
  padding: 2em;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2.5em;
  align-content: center;
  grid-area: testimonial;
`;
export const TestimonialText = styled.div `
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: lighter;
  color: ${theme.colorWhite};
`;
export const LogoWithTextContainer = styled.div `
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1.5em;
  grid-area: logo-testimonial;

  @media screen and ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const LogoContainer = styled.div `
  display: none;
  margin-top: 2.5em;
  align-items: center;

  @media screen and ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    max-width: 100%;
    grid-area: logo;
    justify-items: center;
    align-content: center;
    grid-row-gap: 4em;
  }

  @media screen and ${device.laptop} {
    grid-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and ${device.laptopL} {
    grid-gap: 2em;
    grid-template-columns: repeat(5, 1fr);
  }
`;
export const Logo = styled.img `
  max-height: 5em;
`;
export const LogoWithText = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.2em;

  @media screen and ${device.mobileL} {
    grid-template-columns: 0.5fr 1fr;
    justify-content: center;
    align-items: center;
  }
  @media screen and ${device.laptopL} {
    grid-gap: 0.2em;
  }
`;
export const LogoText = styled.div `
  color: ${theme.colorLightGrey};
  font-size: 1em;
  line-height: 1.5em;
`;
