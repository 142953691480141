import React from "react";
import * as styles from "./styles";
import { useTranslation } from "react-i18next";
const { Container, HashNavLink, NavLink } = styles;
export default ({ collapsedMenu, onClick }) => {
    const { t } = useTranslation("nav");
    const LinkContent = (React.createElement(React.Fragment, null,
        React.createElement(NavLink, { to: "/", onClick: onClick }, t("home")),
        React.createElement(NavLink, { to: "/solutions", onClick: onClick }, t("solutions")),
        React.createElement(HashNavLink, { to: "/#products", onClick: onClick }, t("products")),
        React.createElement(NavLink, { to: "/about-us", onClick: onClick }, t("aboutUs")),
        React.createElement(NavLink, { to: "/contact", onClick: onClick }, t("contact"))));
    return collapsedMenu ? LinkContent : React.createElement(Container, null, LinkContent);
};
