/* eslint-disable */
import { createGlobalStyle } from "styled-components";
import theme from "./theme";
import reset from "./reset";
const GlobalStyles = createGlobalStyle `
  ${reset}
  body {
    font-family: ${theme.fontBody};
    font-size: 14px;
    line-height: 1.2;
    color: ${theme.primaryColor};
  }
  
  #app {
    display: flex;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23fefffa'/%3E%3Cstop offset='1' stop-color='%23ededed'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1' gradientTransform='rotate(295,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23b8b8b8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23b8b8b8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2' gradientTransform='rotate(360,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23b8b8b8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23b8b8b8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.12'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    justify-content: center;
    
    /* Individual item */
    .bm-item {
      display: inline-block;

      /* Our sidebar item styling */
      text-decoration: none;
      margin-bottom: 10px;
      color: ${theme.primaryColor};
      transition: color 0.2s;
      :focus {
        outline: none;
      }
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 27px;
      height: 24px;
      right: 25px;
      top: 21px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 30px !important;
      width: 30px !important;
      right: 24px !important;
      top: 12px !important;
    
     button {
        left: 2px !important;
        top: 4px !important;
      }
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: ${theme.primaryColor};
      height: 30px !important;
    }

    /* General sidebar styles */
    .bm-menu {
      background: ${theme.colorWhite};
      padding: 3.5em 1.5em 0;
      font-size: 1.3em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      display: grid;
      grid-auto-rows: min-content;
      grid-gap: 1.2em;
    }
  }
`;
export default GlobalStyles;
