import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import logo from "assets/images/logo.svg";
import Nav from "components/Nav";
import { useMedia } from "use-media";
import { size } from "styles/utils";
const { Container, BurgerMenu, Logo } = styles;
export default () => {
    const isWide = useMedia({ minWidth: size.laptopS });
    const [menuOpen, setMenuOpen] = useState(false);
    return (React.createElement(Container, null,
        React.createElement(Link, { to: "/" },
            React.createElement(Logo, { src: logo })),
        !isWide ? (React.createElement(BurgerMenu, { right: true, noOverlay: true, isOpen: menuOpen, onStateChange: state => setMenuOpen(state.isOpen) },
            React.createElement(Nav, { collapsedMenu: true, onClick: () => setMenuOpen(false) }))) : (React.createElement(Nav, { collapsedMenu: false }))));
};
