export const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "560px",
    tablet: "768px",
    laptopS: "1024px",
    laptopM: "1440px",
    laptopL: "1920px",
    desktop: "2560px"
};
export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptopS})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};
