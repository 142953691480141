import styled from "styled-components";
import { device } from "styles/utils";
export const Container = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2em;
  padding: 3em;
  background-color: white;
`;
export const List = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 2em;

  @media screen and ${device.laptop} {
    grid-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const FindButtonContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
