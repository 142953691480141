import styled from "styled-components";
import theme from "styles/theme";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export const Container = styled.nav `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 700;
  padding-right: 2em;
`;
export const NavLink = styled(Link) `
  padding: 1em;
  text-decoration: none;
  color: ${theme.primaryColor};
`;
export const HashNavLink = styled(HashLink) `
  padding: 1em;
  text-decoration: none;
  color: ${theme.primaryColor};
`;
