export const solutionsPath = "/solutions";
export const aboutUsPath = "/about-us";
export const contactPath = "/contact";
export const ednarPath = `${solutionsPath}/ednar`;
export const lasarusPath = `${solutionsPath}/lasarus`;
export const plannerToolboxPath = `${solutionsPath}/planner-toolbox`;
export const evolvePath = `${solutionsPath}/evolve`;
export const planningEngineersPath = `${solutionsPath}/planningEngineers`;
export const fieldWorkersPath = `${solutionsPath}/fieldWorkers`;
export const dataEngineersPath = `${solutionsPath}/dataEngineers`;
export const operationalPlannersPath = `${solutionsPath}/operationalPlanners`;
export const managementPath = `${solutionsPath}/management`;
export const developersPath = `${solutionsPath}/developers`;
