import React, { memo } from "react";
import * as styles from "./styles";
const { Container, Heading, TitleContainer, Description, Title, Summary, Logo } = styles;
export default memo(({ title, summary, logoSrc, description }) => {
    return (React.createElement(Container, null,
        React.createElement(Heading, null,
            React.createElement(TitleContainer, null,
                React.createElement(Logo, { src: logoSrc }),
                React.createElement(Title, null, title)),
            React.createElement(Summary, null, summary)),
        React.createElement(Description, null, description)));
});
