import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const Container = styled.div `
  background-color: ${theme.colorWhite};
  padding: 2em;
  margin: 2em 0;
  width: 80%;

  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.2em;

  @media screen and ${device.mobileL} {
    margin: 2em;
    padding: 3em;
    width: 77%;
  }

  @media screen and ${device.laptopL} {
    margin: 7em 10em;
  }
`;
export const Title = styled.div `
  font-weight: bolder;
  font-size: 1.5em;
`;
export const ContactRow = styled.div `
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.2em;
  align-items: center;
  line-height: 1.2em;
`;
export const IconContainer = styled.div `
  font-size: 2em;
`;
export const ImageContainer = styled.div `
  height: 200px;

  @media screen and ${device.mobileL} {
    height: 250px;
  }

  @media screen and ${device.tablet} {
    height: 320px;
  }
`;
export const InformationContainer = styled.div `
  margin-top: 1.2em;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2em;
  align-items: center;
  justify-content: center;

  @media screen and ${device.laptop} {
    grid-gap: 5em;
    grid-template-columns: auto 1fr;
    grid-template-rows: unset;
  }
`;
export const Information = styled.div `
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1.2em;
`;
