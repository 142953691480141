import styled from "styled-components";
import { device } from "styles/utils";
import theme from "styles/theme";
export const Container = styled.div `
  position: relative;
  background-color: ${theme.colorWhite};
  padding: 2em;
  margin: 2em 0;

  @media screen and ${device.mobileL} {
    margin: 2em 2em;
    padding: 3em;
  }

  @media screen and ${device.laptopM} {
    margin: 2em 10em;
    max-width: 1200px;
  }
`;
export const SolutionsContainer = styled.div `
  display: grid;
  grid-template-areas: ${({ targetSelected }) => targetSelected
    ? '"target close close" "subtitle subtitle subtitle"'
    : "auto"};
  margin-top: 2em;

  @media screen and ${device.mobileL} {
    margin: 2.5em;
    grid-template-areas: ${({ targetSelected }) => targetSelected ? '"target close" "subtitle subtitle"' : "auto"};
  }

  @media screen and ${device.laptopM} {
    grid-template-areas: unset;
    grid-template-columns: ${({ targetSelected }) => targetSelected ? "320px 650px 100px" : "1fr"};
    grid-gap: 2em;
  }

  @media screen and ${device.laptopL} {
    margin: ${({ targetSelected }) => targetSelected ? "3.5em 2em" : "2em 1.75em"};
  }
`;
export const DescriptionContainer = styled.div `
  grid-area: description;
  font-weight: 400;
  font-size: 1em;
  line-height: 2em;

  p {
    margin-top: 0.8em;
  }

  @media screen and ${device.laptopM} {
    margin: 2.5em;
    grid-area: unset;
  }
`;
export const Title = styled.div `
  color: ${theme.colorDarkGrey};
  margin: 0 2em;
`;
export const TargetContainer = styled.div `
  display: grid;
  grid-template-columns: ${({ targetSelected }) => targetSelected ? "1fr" : "repeat(1, 1fr)"};
  grid-gap: 6em;
  justify-items: ${({ targetSelected }) => targetSelected ? "flex-start" : "center"};
  align-content: flex-start;
  transition: 1s ease;
  margin-top: 2em;
  grid-area: target;

  @media screen and ${device.tablet} {
    grid-template-columns: ${({ targetSelected }) => targetSelected ? "1fr" : "repeat(2, 1fr)"};
  }

  @media screen and ${device.laptop} {
    grid-area: unset;
    grid-template-columns: ${({ targetSelected }) => targetSelected ? "1fr" : "repeat(3, 1fr)"};
  }
  @media screen and ${device.laptopL} {
    grid-column-gap: 6em;
    grid-row-gap: 2.5em;
  }
`;
export const Target = styled.div `
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 2em;
  :hover {
    cursor: ${({ targetSelected }) => (targetSelected ? "unset" : "pointer")};
  }
`;
export const TargetTitle = styled.div `
  font-size: ${({ targetSelected }) => (targetSelected ? "1.5em" : "1.2em")};
  font-weight: bolder;
  justify-self: start;

  @media screen and ${device.laptop} {
    justify-self: center;
  }
`;
export const ImageContainer = styled.div `
  width: 250px;
`;
export const Solutions = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 3em;
  margin-top: 4em;

  @media screen and ${device.mobileL} {
    margin: unset;
  }

  @media screen and ${device.laptop} {
    grid-area: unset;
    grid-gap: 1em;
    grid-row-gap: 3em;
    margin: 2.5em;
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const CloseButton = styled.div `
    position: absolute;
    font-size: 2.5rem;
    top: 0.5em;
    right: 0px;
    cursor: pointer;

    @media screen and ${device.mobileL} {
      top: 1em;
      right: 0.5em;
    }

    @media screen and ${device.tablet} {
      right: 1em;
    }
  }
`;
export const SubtitleContainer = styled.div `
  grid-area: subtitle;
  font-size: 1.25rem;
  line-height: 2.5em;
  color: ${theme.primaryColor};
  margin-top: 1.2em;
  align-self: center;

  @media screen and ${device.laptopM} {
    margin-top: unset;
    grid-area: unset;
  }
`;
